import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Formik } from "formik";
import { map } from "lodash";

import Title from "../../../Biomarkers/Details/Title";
import BaseLayoutWithCard from "../../../../../base/components/BaseLayoutWithCard";
import FormikInput from "../../../../../base/components/FormikInput";
import Button from "../../../../../base/components/Button";
import FormikReactSelect from "../../../../../base/components/FormikReactSelect";
import {
  UserSegmentsDropdown
} from "../../../../../base/components/Dropdowns/UserSegmentsDropdown/UserSegmentsDropdown";
import { DropZonePlaceholder } from "../../../Fitness/WorkoutForm/form";
import { FormAvatar } from "../../../../../base/components/Dropzone/Dropzone";
import { TagsDropdown } from "../../../../../base/components/Dropdowns/TagsDropdown/TagsDropdown";
import { CoachDropdown } from "./Dropdowns/CoachDropdown/CoachDropdown";

import ToastService from "../../../../../services/ToastService";

import { useService } from "../../../../../base/hooks/useService";
import { useQueryString } from "../../../../../base/hooks/useQueryString";
import useUploadImages from "../../../../../base/components/Dropzone/useUploadImages";
import joinClassNames from "../../../../../base/helpers/joinClassNames";
import { WELLNESS_GROUP_LINKS } from "../config";
import { MAX_RULE_NAME_LENGTH } from "../../../Biomarkers/CreateEdit/constants";
import { BUTTON_COLORS } from "../../../../../base/components/Button/appearance";
import { COACH_FILE_TYPE, WELLNESS_FILE_TYPE } from "../../../../../base/constants/shared";
import { initialValues, mapProgramTypesToOption, mapSubcategoryToOption, validateFile, validationSchema } from "./form";
import { programStatusOptions, WELLNESS_TYPE } from "../../constants";
import RichTextEditor from "../../../../../base/components/RichTextEditor";
import { MAX_PROGRAM_HIGHLIGHT, MAX_PROGRAM_OVERVIEW } from "./constants";
import WellnessService from "../../../../../services/WellnessService";
import { INSTANCE_API_FIELD } from "../constants";
import HealthProgramService from "../../../../../services/HealthProgramService";
import { CROP_IMAGE_RATIO_16_9 } from "../../../../../base/constants/colorsAndSIzes";
import { MAX_WHY_SYSTEM_CAN_RECOMMEND_DESCRIPTION } from "../../Nutrition/CreateEditProgram/constants";
import { PlanOfAction } from "./components/PlanOfAction";
import { chain } from "lodash";
import { MAX_SIZE_MB, MAX_WIDTH_OR_HEIGHT, USE_WEB_WORKER } from "../../../../../base/constants/image";
import imageCompression from "browser-image-compression";
import SubscriptionPlansService from "../../../../../services/SubscriptionPlansService";

export function WellnessProgramForm() {
  /**
   * @type {WellnessService}
   */
  const wellnessService = useService(WellnessService);
  /**
   * @type {HealthProgramService}
   */
  const healthProgramService = useService(HealthProgramService);
  /**
   * @type {ToastService}
   */
  const toastService = useService(ToastService);
  /**
   * @type {SubscriptionPlansService}
   */
  const subscriptionPlansService = useService(SubscriptionPlansService);

  const uploadImage = useUploadImages();
  const { search: locationSearch } = useLocation();
  const navigate = useNavigate();

  const [isSubmitting, updateIsSubmitting] = useState(false);
  const [program, setProgram] = useState(null);
  const [programTypes, setProgramTypes] = useState([]);
  const [subscriptionPlans, setSubscriptionPlans] = useState([
    { value: null, label: "Free" }
  ]);

  const {
    params: {
      editProgramId
    }
  } = useQueryString(locationSearch);

  const afterSuccess = () => {
    toastService.success("Program has been successfully saved");
    navigate(WELLNESS_GROUP_LINKS.BASE);
    updateIsSubmitting(false);
  };

  const apiFunction = (program) => {
    if (editProgramId) {
      return wellnessService.updateProgram(editProgramId, program);
    }

    return wellnessService.createProgram(program);
  };

  const mapFormValuesToProgram = ({
                                    userSegments = [],
                                    programType,
                                    subcategory,
                                    programDays,
                                    programOverview,
                                    programHighlights,
                                    wellnessActionPlans,
                                    ...otherValues
                                  }) => {
    return {
      wellnessType: WELLNESS_TYPE.WELLNESS,
      wellnessProgramTypeId: programType,
      wellnessProgramSubcategoryId: subcategory,
      programDays: programDays?.map(({ instance, contentType, ...item }, index) => ({
        ...item,
        instanceId: instance?.id || null,
        type: contentType,
        day: index + 1
      })),
      overview: programOverview,
      highlights: programHighlights,
      segmentIds: map(userSegments, "id") || [],
      actionPlans: wellnessActionPlans?.map(({ level, actionPlans }) => ({
        level,
        actionPlans: actionPlans.map(({ step, contentId }) => ({
          contentId,
          step
        }))
      })),
      ...otherValues
    };
  };

  const createProgram = async ({ file, coach, ...otherValues }) => {
    updateIsSubmitting(true);

    let fileId = file?.[0]?.id;
    let coachFileId = coach?.file?.[0]?.id;
    const coachName = coach?.name;
    const options = {
      maxSizeMB: MAX_SIZE_MB,
      maxWidthOrHeight: MAX_WIDTH_OR_HEIGHT,
      useWebWorker: USE_WEB_WORKER,
    }

    if (file?.[0]?.file && !file?.[0]?.id) {
      const compressedFile = await imageCompression(file[0].file, options);
      const { file: uploadedFile } = await uploadImage(compressedFile, WELLNESS_FILE_TYPE);
      fileId = uploadedFile.id;
    }

    if (coach?.file?.[0]?.file && !coach?.file?.[0]?.id) {
      const compressedCoachFile = await imageCompression(coach?.file?.[0]?.file, options);
      const { file: uploadedFile } = await uploadImage(compressedCoachFile, COACH_FILE_TYPE);
      coachFileId = uploadedFile.id;
    }

    apiFunction(mapFormValuesToProgram({
      ...otherValues,
      fileId,
      coachFileId,
      coachName
    })).then(afterSuccess).finally(() => updateIsSubmitting(false));
  };

  const mapProgramToForm = (program) => {
    return {
      name: program.name,
      programType: program.wellnessProgramTypeId,
      subcategory: program.wellnessProgramSubcategoryId,
      file: program?.file
        ? [{
          ...(program?.file || {}),
          preview: program?.file?.link,
          file: { size: program?.file?.bytes ?? 0 },
          cropped: true
        }]
        : [],
      coach: {
        name: program.coach?.name,
        file: program.coach?.file
          ? [{
            ...(program.coach?.file || {}),
            preview: program.coach?.file?.link,
            file: { size: program.coach?.file?.bytes ?? 0 },
            cropped: true
          }]
          : []
      },
      status: program.status.toString(),
      userSegments: program?.segments?.map(item => ({
        id: item.segment.id,
        name: item.segment.fullName
      })),
      tagNames: program.tags?.map(item => item.tag.name) || [],
      programHighlights: program.highlights,
      programOverview: program.overview,
      programDays: program.wellnessProgramDays?.map((item) => {
        const instance = item[INSTANCE_API_FIELD[item.type]];

        return {
          isRest: item.isRest,
          isOptional: item.isOptional,
          contentType: item.type?.toString(),
          instance: instance && {
            id: instance?.id || instance?._id,
            name: instance?.name || instance?.foodName || instance?.title
          }
        };
      }),
      wellnessActionPlans: chain(program)
        .get('wellnessActionPlans')
        .groupBy('level')
        .map((items, level) => ({
          level: level,
          actionPlans: items.map((item) => ({
            step: item.step,
            contentId: item?.contentId,
            name: item?.content?.title,
          }))
        })).value(),
      whyRecommendedDescription: program.whyRecommendedDescription,
      subscriptionPlanId: program?.subscriptionPlan?.id,
    };
  };

  useEffect(() => {
    if (editProgramId) {
      wellnessService.getProgramById(editProgramId)
        .then((data) => {
          setProgram(mapProgramToForm(data));
        });
    }
  }, [editProgramId]);

  useEffect(() => {
    healthProgramService.getWellnessTypeAndSubcategories(WELLNESS_TYPE.WELLNESS)
      .then((result => {
        setProgramTypes(result.data);
      }));
    subscriptionPlansService.getSubscriptionPlans()
      .then(({ data }) => {
        setSubscriptionPlans([
          { value: null, label: "Free" },
          ...data.map(({ id, adaptyProductName }) => ({ value: id, label: adaptyProductName }))
        ])
      });
  }, []);

  const breadcrumbs = {
    title: editProgramId ? "Edit Wellness Program" : "Create Wellness Program",
    breadcrumbItems: [
      { title: "Wellness programs", link: WELLNESS_GROUP_LINKS.BASE },
      { title: editProgramId ? "Edit program" : "Create program" }
    ]
  };

  return <BaseLayoutWithCard breadcrumbs={breadcrumbs}>
    <Formik
      initialValues={program || initialValues}
      validationSchema={validationSchema}
      validateOnBlur
      onSubmit={createProgram}
      enableReinitialize
    >
      {({ errors, handleSubmit, values, setFieldValue }) => {
        const selectedProgramType = programTypes.find((item => item.id?.toString() === values.programType?.toString()))

        return <form
          className={joinClassNames("form-horizontal p-2", isSubmitting && "pointer-events-none")}
          onSubmit={handleSubmit}
        >
          <div className="w-50">
            <div className="d-flex justify-content-between align-items-center mb-4">
              <Title
                title="General information"
              />
            </div>
            <section>
              <FormikReactSelect
                name="programType"
                options={mapProgramTypesToOption(programTypes)}
                label="Program type"
                setFieldValue={async (name, value) => {
                  await setFieldValue("subcategory", null)
                  await setFieldValue(name, value)
                }}
                placeholder="Select program type (required)"
                backendError={errors.programType}
                containerClassName="mt-3"
              />

              <FormikReactSelect
                name="subcategory"
                options={mapSubcategoryToOption(selectedProgramType?.wellnessProgramSubcategories)}
                label="Subcategory"
                setFieldValue={setFieldValue}
                placeholder="Enter subcategory (required)"
                backendError={errors.subcategory}
                disabled={!values.programType}
                containerClassName="mt-3"
              />

              <div className="mt-3">
                <FormAvatar
                  validateImage={validateFile}
                  dropZonePlaceholder={<DropZonePlaceholder/>}
                  dropZoneTitle={<div className="text-center">
                    <div>Upload an image</div>
                    <div>(Optional)</div>
                  </div>}
                  cropperSetting={CROP_IMAGE_RATIO_16_9}
                />
              </div>

              <FormikInput
                placeholder="Enter name (required)"
                name="name"
                maxLength={MAX_RULE_NAME_LENGTH}
                label="Name"
                containerClassName="mt-3"
              />

              <div className="mt-3">
                <CoachDropdown
                  onChange={(value) => {
                    setFieldValue("coach", value);
                  }}
                  value={values.coach}
                  placeholder="Select coach (optional)"
                  label="Coach"
                  error={errors.coach}
                />
              </div>

              <div className="mt-3">
                <UserSegmentsDropdown
                  value={values.userSegments}
                  onChange={(value) => setFieldValue("userSegments", value)}
                  placeholder="Select user segments (required)"
                  label="User segments"
                  error={errors.userSegments}
                />
              </div>

              <div className="mt-3">
                <TagsDropdown
                  value={values.tagNames}
                  onChange={(value) => setFieldValue("tagNames", value)}
                  placeholder="Enter tags (optional)"
                  label="Tags"
                  error={errors.tagNames}
                />
              </div>

              <FormikReactSelect
                name="status"
                options={programStatusOptions}
                label="Status"
                setFieldValue={setFieldValue}
                placeholder="Select status (required)"
                backendError={errors.status}
                containerClassName="mt-3"
                withError
              />

              <FormikReactSelect
                name="subscriptionPlanId"
                options={subscriptionPlans}
                label="Subscription type"
                setFieldValue={setFieldValue}
                containerClassName="mt-3"
                placeholder="Select subscription type (required)"
                withError
              />

              <RichTextEditor
                value={values.programOverview || initialValues.programOverview}
                onChange={(text) => {
                  setFieldValue('overview', text);
                }}
                maxLength={MAX_PROGRAM_OVERVIEW}
                name="programOverview"
                label="Program overview"
                placeholder="Enter the overview for the workout programs..."
              />

              <RichTextEditor
                value={values.programHighlights || initialValues.programHighlights}
                onChange={(text) => {
                  setFieldValue('highlights', text);
                }}
                maxLength={MAX_PROGRAM_HIGHLIGHT}
                name="programHighlights"
                label="Program highlights"
                placeholder="Enter the workout highlights..."
              />
            </section>

            <label htmlFor="" className="editor-container__label mb-2 mt-3">Plan of Action (required)</label>
            <PlanOfAction/>

            <RichTextEditor
              value={values.whyRecommendedDescription || initialValues.whyRecommendedDescription}
              onChange={(text) => {
                setFieldValue('whyRecommendedDescription', text);
              }}
              maxLength={MAX_WHY_SYSTEM_CAN_RECOMMEND_DESCRIPTION}
              className="mt-5"
              name="whyRecommendedDescription"
              label="Describe why system can recommend this program"
              placeholder="Enter why system can recommend this program..."
            />

            <div className="d-flex justify-content-end mt-5">
              <Button
                className="d-flex gap-2 align-items-center"
                color={BUTTON_COLORS.primary}
                type="submit"
                // disabled={!!Object.keys(errors).length || isSubmitting}
              >
                {isSubmitting ?
                  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : null }
                Save program
              </Button>
            </div>
          </div>
        </form>;
      }}
    </Formik>
  </BaseLayoutWithCard>;
}