import yup from "../../../../../validation/yup";
import {
    ALLOWED_EXTENTIONS_FILE,
    MAX_PROGRAM_NAME_LENGTH,
    MIN_STRING_LENGTH,
    ERROR_ALLOWED_TYPE_MESSAGE,
    MIN_USER_SEGMENTS_ITEMS_LENGTH,
    TAG_NAMES_MAX_ITEMS_LENGTH, MIN_LENGTH_PROGRAM_DAYS
} from "./constants";
import {
    ERROR_SIZE_MESSAGE,
    isAllowedExtension,
    MAX_FILE_SIZE
} from "../../../../../base/components/Dropzone";
import { MAX_WHY_SYSTEM_CAN_RECOMMEND_DESCRIPTION } from "../../Nutrition/CreateEditProgram/constants";

const isUnique = (arr) => new Set(arr).size === arr?.length;

export const initialProgramDay = {
    isRest: false,
    isOptional: false,
    contentType: null
}

export const initialValues = {
    name: "",
    file: [],
    userSegments: [],
    tagNames: [],
    highlights: '',
    overview: '',
    programDays: [{}],
    wellnessActionPlans: [{
        level: 1,
        actionPlans: [
            {
                step: 1
            }
        ]
    }],
    whyRecommendedDescription: "",
};

export const validationSchema = yup.object().shape({
    programType: yup.mixed().required(),
    subcategory: yup.string().required(),
    coach: yup.object().test(
        'file-name-check',
        'Enter coach name or remove the photo.',
        function (value) {
            const { name, file } = value || {};
            if (Array.isArray(file) && file.length > 0) {
                if (!name || name.trim() === '') {
                    return this.createError({ path: 'coach', message: 'Enter coach name or remove the photo.' });
                }
            }
            return true;
        }
    ),
    name: yup.string()
        .trim()
        .min(MIN_STRING_LENGTH)
        .max(MAX_PROGRAM_NAME_LENGTH)
        .required(),
    userSegments: yup.array().min(MIN_USER_SEGMENTS_ITEMS_LENGTH, 'User segments is required'),
    status: yup.string().required(),
    tagNames: yup.array()
        .max(TAG_NAMES_MAX_ITEMS_LENGTH, `Maximum ${TAG_NAMES_MAX_ITEMS_LENGTH} tags allowed.`)
        .test('unique', 'Tags must be unique', isUnique),
    whyRecommendedDescription: yup
      .string()
      .max(MAX_WHY_SYSTEM_CAN_RECOMMEND_DESCRIPTION)
});

export const validateFile = (file) => {
    if (file?.size > MAX_FILE_SIZE) {
        return ERROR_SIZE_MESSAGE;
    }

    if (!isAllowedExtension(file, ALLOWED_EXTENTIONS_FILE)) {
        return ERROR_ALLOWED_TYPE_MESSAGE;
    }

    return null;
};

export const mapSubcategoryToOption = (subcategories = []) => {
    return subcategories.map((value) => ({
        id: value.id,
        value: value.id,
        label: value.title
    }));
};

export const mapProgramTypesToOption = (subcategories = []) => {
    return subcategories.map((value) => ({
        id: value.id,
        value: value.id,
        label: value.title,
    }));
};