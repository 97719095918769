import * as yup from "yup";
import {
    ALLOWED_EXTENTIONS_FILE,
    ALLOWED_SYMBOLS_REGEX,
    MIN_STRING_LENGTH,
    ERROR_ALLOWED_TYPE_MESSAGE,
    MAX_NAME_LENGTH,
    ALLOWED_SYMBOLS_MESSAGE,
    WORKOUT_LEVEL,
    WORKOUT_LEVEL_LABEL,
    WORKOUT_STATUSES,
    WORKOUT_STATUSES_LABELS,
    WORKOUT_DESCRIPTION_TYPE,
    WORKOUT_CONTENT_FORMAT,
    ALLOWED_SYMBOLS_SECTION_NAME_REGEX,
    WORKOUT_SECTION_TOPIC_TYPES, MIN_REPETITIONS, MAX_REPETITIONS
} from "./constants";
import {
    ERROR_SIZE_MESSAGE,
    isAllowedExtension,
    MAX_FILE_SIZE
} from "../../../../base/components/Dropzone";
import { VIMEO_VIDEO_PATTERN } from "../../../../base/constants/patterns";
import { EXERCISE_TYPES } from "../ExerciseForm/constants";
import React from "react";
import { map } from "lodash";
import { parseDurationStringFormatToObjectValue } from "../../../../base/helpers/parseDurationValue";
import { MAX_WHY_SYSTEM_CAN_RECOMMEND_DESCRIPTION } from "../../HealthProgram/Nutrition/CreateEditProgram/constants";

export const initialValues = {
    name: "",
    file: [],
    typeId: null,
    content: WORKOUT_CONTENT_FORMAT.BASIC_WORKOUT,
    descriptionType: WORKOUT_DESCRIPTION_TYPE.WORKOUT_SET_UP,
    duration: { hours: '00', minutes: '00', seconds: '00' },
    sections: [],
    overview: '',
    whyRecommendedDescription: ''
};

const videoUrlValidator = yup
    .string()
    .matches(
        VIMEO_VIDEO_PATTERN,
        'Invalid url'
    )
    .required();

const sectionNameValidator = yup
    .string()
    .trim()
    .matches(ALLOWED_SYMBOLS_SECTION_NAME_REGEX, 'Allowed symbols: Alphanumeric, spaces, dashes, underscores')
    .required();

const setsValidationSchemas = {
    [EXERCISE_TYPES.WEIGHTS]: yup.array().of(
        yup.object().shape({
            reps: yup.mixed(),
            rest: yup.mixed(),
            duration: yup.mixed(),
            intensity: yup.mixed(),
            distance: yup.mixed(),
            weight: yup.mixed()
        })
    ),
    [EXERCISE_TYPES.CARDIO]: yup.array().of(
        yup.object().shape({
            reps: yup.mixed(),
            rest: yup.mixed(),
            duration: yup.mixed(),
            intensity: yup.mixed(),
            distance: yup.mixed(),
            weight: yup.mixed()
        })
    ),
    [EXERCISE_TYPES.MOBILITY]: yup.array().of(
        yup.object().shape({
            reps: yup.mixed(),
            rest: yup.mixed(),
            duration: yup.mixed(),
            intensity: yup.mixed(),
            distance: yup.mixed(),
            weight: yup.mixed()
        })
    ),
    [EXERCISE_TYPES.OTHER]: yup.array().of(
        yup.object().shape({
            reps: yup.mixed(),
            rest: yup.mixed(),
            duration: yup.mixed(),
            intensity: yup.mixed(),
            distance: yup.mixed(),
            weight: yup.mixed()
        })
    )
};

const exerciseSchema = yup.object().shape({
    id: yup.number().required(),
});

const getExerciseValidationSchema = (exerciseType) => {
    return ((exerciseType && setsValidationSchemas[EXERCISE_TYPES.OTHER]) || yup.array().of(
        yup.object().shape({
            reps: yup.number().optional(),
            rest: yup.number().optional(),
            weight: yup.number().optional(),
            duration: yup.number().optional(),
            intensity: yup.string().optional()
        })
    )).test(
        'at-least-one-field',
        'Please fill in at least one set field or remove the unnecessary set row.',
        function(value) {

            if (!value || value.length === 0) return true;

            if (value.length === 1) {
                return true;
            }

            return !value.some(item => {
                return !Object.values(item).some(field => !!field)
            });
        }
    );
};

const sectionValidationSchema = yup.object().shape({
    name: sectionNameValidator,
    isEditTitle: yup.boolean().oneOf([false], 'isEditTitle must be false').required(),
    exercises: yup.array().of(
        yup.lazy((item) =>
            yup.object().shape({
                exercise: yup.mixed().when('type', {
                    is: WORKOUT_SECTION_TOPIC_TYPES.EXERCISE,
                    then: exerciseSchema,
                    otherwise: yup.mixed().notRequired()
                }),
                sets: yup.mixed().when('type', {
                    is: WORKOUT_SECTION_TOPIC_TYPES.EXERCISE,
                    then: yup.lazy(() => {
                        return getExerciseValidationSchema(item?.exercise?.type);
                    }),
                    otherwise: yup.mixed().notRequired()
                }),
                exercises: yup.mixed().when('type', {
                    is: WORKOUT_SECTION_TOPIC_TYPES.EXERCISE,
                    then: yup.mixed().notRequired(),
                    otherwise: yup.array().of(
                        yup.lazy((item) =>
                            yup.object().shape({
                                exercise: exerciseSchema,
                                sets: yup.lazy(() => {
                                    return getExerciseValidationSchema(item?.exercise?.type);
                                })
                            })
                        )
                    )
                }),
                repetitions: yup.mixed().when('type', {
                    is: WORKOUT_SECTION_TOPIC_TYPES.EXERCISE,
                    then: yup.mixed().notRequired(),
                    otherwise: yup.number()
                        .min(MIN_REPETITIONS, `Repetitions must be greater than or equal to ${MIN_REPETITIONS}`)
                        .max(MAX_REPETITIONS, `Repetitions must be less than or equal to ${MAX_REPETITIONS}`)
                        .required()
                }),
            })
        )
    )
});

export const validationSchema = yup.object().shape({
    name: yup.string()
        .trim()
        .min(MIN_STRING_LENGTH)
        .max(MAX_NAME_LENGTH)
        .matches(ALLOWED_SYMBOLS_REGEX, ALLOWED_SYMBOLS_MESSAGE)
        .required(),
    level: yup.string().required(),
    typeId: yup.mixed().required(),
    status: yup.string().required(),
    sections: yup.array().of(
        sectionValidationSchema
    ).test('unique', 'Section names must be unique', function (sections) {
        const sectionNames = sections?.map(section => section.name || '');
        const uniqueSectionNames = new Set(sectionNames);
        return uniqueSectionNames.size === sectionNames.length;
    }),
    videoUrl: yup.mixed().when('descriptionType', {
        is: WORKOUT_DESCRIPTION_TYPE.WORKOUT_VIDEO,
        then: videoUrlValidator,
        otherwise: yup.mixed().optional()
    }),
    whyRecommendedDescription: yup
      .string()
      .max(MAX_WHY_SYSTEM_CAN_RECOMMEND_DESCRIPTION, `Maximum ${MAX_WHY_SYSTEM_CAN_RECOMMEND_DESCRIPTION} characters allowed`)
});


export const validateFile = (file) => {
    if (file?.size > MAX_FILE_SIZE) {
        return ERROR_SIZE_MESSAGE;
    }

    if (!isAllowedExtension(file, ALLOWED_EXTENTIONS_FILE)) {
        return ERROR_ALLOWED_TYPE_MESSAGE;
    }

    return null;
};

export const workoutLevelOptions = Object.values(WORKOUT_LEVEL).map((value) => ({
    value,
    id: value,
    label: WORKOUT_LEVEL_LABEL[value]
}));

export const workoutStatusesOption = Object.values(WORKOUT_STATUSES).map((value) => ({
    id: value,
    value,
    label: WORKOUT_STATUSES_LABELS[value]
}));

const mapSetToApiFormat = (sets = []) => {

    return sets.map((set) => ({
        time: set.duration && `${set.duration?.minutes || '00'}m ${set.duration?.seconds || '00'}s`,
        repetitions: set.reps && Number(set.reps),
        rest: set.rest && Number(set.rest),
        intensity: set.intensity && Number(set.intensity),
        distance: set.distance && Number(set.distance),
        weight: set.weight && Number(set.weight)
    }))
}

const mapExerciseToApiFormat = (exerciseValue) => {
    const { type, exercise, exercises, repetitions, sets, rest } = exerciseValue;

    if (!type || type === WORKOUT_SECTION_TOPIC_TYPES.EXERCISE) {
        return {
            exerciseId: exercise.id,
            sets: mapSetToApiFormat(sets)
        };
    } else {
        return {
            type: Number(type),
            exercises: exercises?.map(mapExerciseToApiFormat),
            repetitions,
            rest
        };
    }
};

export const mapFormValuesToWorkout = ({ typeId, userSegments, duration, sections, overview, ...otherValues }) => {
    const mappedSections = sections.map(({ name, exercises }) => {
        return {
            name,
            exercises: exercises.map(mapExerciseToApiFormat)
        };
    });

    return {
        typeId: Number(typeId?.id),
        duration: `${duration.hours || 0}h ${duration.minutes  || 0}m ${duration.seconds || 0}s`,
        sections: mappedSections,
        overview: overview || null,
        segmentsIds: userSegments?.length ? map(userSegments, 'id') : null,
        ...otherValues
    };
};


const mapSetsToForm = (sets) => {
    return sets.map(set => {
        return ({
            duration: set.time ? parseDurationStringFormatToObjectValue(set.time, true) : null,
            reps: set.repetitions || null,
            rest: set.rest || null,
            intensity: set.intensity ? set.intensity.toString() : null,
            distance: set.distance || null,
            weight: set.weight || null
        })
    })
}

const mapExerciseToForm = (exercise) => {
    return {
        exercise: {
            type: exercise.type,
            title: exercise.title,
            id: exercise.exerciseId,
        },
        sets: mapSetsToForm(exercise.sets),
        type: WORKOUT_SECTION_TOPIC_TYPES.EXERCISE
    }
}

const mapSectionsToForm = (sections) => {
    return sections.map(({name, exercises}) => {
        return {
            name,
            isEditTitle: false,
            exercises: exercises.map(({ type, rest, repetitions, exercises, exerciseId, title, sets }) => {
                if (exerciseId) {
                    return mapExerciseToForm({ exerciseId, title, sets, type })
                } else {
                    return {
                        rest,
                        repetitions,
                        type,
                        exercises: exercises.map(mapExerciseToForm)
                    }
                }
            })
        }
    })
}

export const mapWorkoutToForm = ({
                              name,
                              level,
                              type,
                              segments = [],
                              status,
                              duration,
                              content,
                              descriptionType,
                              sections,
                              videoUrl,
                              file,
                              overview,
                              whyRecommendedDescription,
                              subscriptionPlanId,
                          }) => {
    return {
        name,
        level,
        typeId: type && {id: type.id, name: type.name},
        userSegments: segments.map(({fullName, id}) => ({ name: fullName, id })),
        duration: parseDurationStringFormatToObjectValue(duration, false),
        content,
        descriptionType,
        status,
        sections: mapSectionsToForm(sections),
        videoUrl,
        file: file
            ? [{
                ...(file || {}),
                preview: file?.link,
                file: { size: file?.bytes ?? 0 },
                cropped: true
            }]
            : [],
        overview,
        whyRecommendedDescription,
        subscriptionPlanId,
    };
};

export function DropZonePlaceholder() {
    return (
        <>
            <p className="upload-container--section__desc mb-0">Drop PNG, JPEG, and JPG files with transparent backgrounds.</p>
            <p className="upload-container--section__desc">Max 10 Mb</p>
        </>
    )}
